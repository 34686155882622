// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/yomua/yomua/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/component/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'index' */'@/index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "redirect": "/index",
        "exact": true
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/type",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__type' */'@/pages/type'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/mood",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mood' */'@/pages/mood'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/about",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about' */'@/pages/about'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/feature/:name*",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__feature' */'@/pages/feature'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/*",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
